import AppContextService from './AppContextService.js';
import UserAgentService from './UserAgentService.js';

const CookieBotService = {
  verifyUserAgentSuitability: (queryString) => {
    const isMobileAppOrigin = AppContextService.isMobileAppOrigin(queryString);
    const isIOS = UserAgentService.isIOS();

    const isUserAgentUnsuitable = isMobileAppOrigin && isIOS;
    if (isUserAgentUnsuitable) {
      handleUnsuitableUserAgent();
    }
  },
};

export default CookieBotService;

const cookieOverlayClass = 'cookies-overlay';
const necessaryCookiesAcceptButtonId = 'CybotCookiebotDialogBodyButtonDecline';
const cookieBotUnsuitableClass = 'cookie-bot-unsuitable';

const handleUnsuitableUserAgent = () => {
  document.body.classList.add(cookieBotUnsuitableClass);

  if (isCookieBotLoaded()) {
    acceptNecessaryCookies();
  } else {
    subscribeToCookieBotLoad();
  }
};

const subscribeToCookieBotLoad = () => {
  const observer = new MutationObserver((_, observer) => {
    if (isCookieBotLoaded()) {
      observer.disconnect();
      acceptNecessaryCookies();
      return;
    }
  });

  observer.observe(document.body, { childList: true });
};

const isCookieBotLoaded = () => !!getNecessaryCookiesAcceptButton();

const getNecessaryCookiesAcceptButton = () => {
  const overlayElementList =
    document.getElementsByClassName(cookieOverlayClass);
  const overlayElementLoaded = !!overlayElementList.length;

  if (overlayElementLoaded) {
    const necessaryCookiesAcceptButton = document.getElementById(
      necessaryCookiesAcceptButtonId
    );

    return necessaryCookiesAcceptButton;
  }
};

const acceptNecessaryCookies = () => {
  const necessaryCookieAcceptButton = getNecessaryCookiesAcceptButton();

  necessaryCookieAcceptButton?.click();
};
