import React, { Component, createRef } from "react";
import InputText from "../components/InputText.jsx";
import validator from "../components/FieldValidator.jsx";
import "regenerator-runtime/runtime";
import fetcherApi from '../components/FetcherApi.jsx';
import Spinner from '../profile/Information/Spinner.jsx';
import LegalEntityFunctions from "../../components/components/LegalEntityFunctions.jsx";
import Footer from "../../components/components/Footer.jsx";
import NotificationSuccess from "../components/Notifications/NotificationSuccess.jsx";
import ConsentLinks from "../components/Consents/ConsentLinks.jsx";
import * as constants from "../constants/notificationTypes.jsx";
import TranslationText from "../components/Translation/TranslationText.jsx";
import EmailAndPhoneValidator from "../components/EmailAndPhoneValidator.jsx";
import { withTranslation } from 'react-i18next';
import i18n from "translations/i18n.jsx";
import AutoHideableTitle from "../components/HeaderComponents/AutoHideableTitle.jsx";
import urlTools from "../tools/urlTools.jsx";
import { Link } from "react-router-dom/cjs/react-router-dom.js";

class BusinessProfileInformation extends Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.notificationPopupRef = createRef();
	}

	state = {
		phonePrefixes: this.props.initialData.phonePrefixes.map(data => ({ label: data.value, value: data.key })),
		availableCountries: this.props.initialData.availableCountries.map(data => ({ label: data.value, value: data.key })),
		errors: {},
		clientData: null,
		initialClientData: {},
		companyInformation: null,
		companyInformation: null,
		initialCompanyInformation: {},
		submiterror: "",
		dirtyfields: {},
		mandatoryFieldsErrors: {},
		legalEntityId: this.props.initialData.legalEntityId,
		maxPhoneLength: ""
	};

	createSelectItems() {
		const { phonePrefixes } = this.state;
		let items = [];
		for (let i = 0; i < phonePrefixes.length; i++) {
			let label = phonePrefixes[i].label;
			let key = phonePrefixes[i].value;
			items.push(<option key={label} value={key}>{label}</option>);
		}
		return items;
	}

	createSelectCountryItems() {
		const { availableCountries } = this.state;
		let items = [];
		for (let i = 0; i < availableCountries.length; i++) {
			let label = availableCountries[i].label;
			let key = availableCountries[i].value;
			let translationKey = 'country_name_' + label.toLowerCase();
			items.push(<option key={label} value={key}>{i18n.t(translationKey)}</option>);
		}
		return items;
	}

	async componentDidMount() {
		this._isMounted = true;
		const b2bProfileData = (await fetcherApi.get(urlTools.constructRoute("api/B2bProfile/profileInformation/" + this.state.legalEntityId))).response.data.resultObject;
		if (this._isMounted) {
			let initialMandatoryFieldErrors = {};
			if (!b2bProfileData.clientData.firstName) {
				initialMandatoryFieldErrors['firstName'] = "profile_information_page_mandatory_field_error";
			}
			this.setState({
				clientData: b2bProfileData.clientData,
				companyInformation: b2bProfileData.companyInformation,
				initialClientData: JSON.parse(JSON.stringify(b2bProfileData.clientData)),
				initialCompanyInformation: JSON.parse(JSON.stringify(b2bProfileData.companyInformation)),
				dirtyfields: {}, mandatoryFieldsErrors: initialMandatoryFieldErrors,
				errors: {},
				maxPhoneLength: validator.getPhoneMinLength(this.props.initialData.phonePrefixes, b2bProfileData.clientData.phonePrefix),
			});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.setState = (state, callback) => {
			return;
		}
	}

	onChange = e => {
		const { clientData, dirtyfields, initialClientData, mandatoryFieldsErrors, errors } = this.state;

		if (e.prop === 'email') {
			e.value = validator.blockSpaceSymbol(e.value);
		}

		if (initialClientData[e.prop] !== e.value) {
			dirtyfields[e.prop] = true;
		}
		else {
			dirtyfields[e.prop] = false;
		}

		if (e.prop === 'lastName' || e.prop === 'firstName') {
			clientData[e.prop] = validator.blockUnwantedSymbols(e.value);
		}
		else if (e.prop === 'email') {
			clientData[e.prop] = validator.blockSpaceSymbol(e.value);
		}
		else {
			clientData[e.prop] = validator.validate(e.prop, e.value);
		}

		if (e.prop === 'email' && !EmailAndPhoneValidator.IsEmailStructureValid(e.value)) {
			mandatoryFieldsErrors[e.prop] = 'empty_email_error_message';
		}
		else {
			mandatoryFieldsErrors[e.prop] = validator.validateMandatoryField(e.prop, clientData[e.prop], true,
				this.props.initialData.validations);
		}

		errors[e.prop] = [];

		this.setState({ clientData, dirtyfields, errors, mandatoryFieldsErrors });
	};

	onChangeCompany = e => {
		const { companyInformation, initialCompanyInformation, dirtyfields, errors, mandatoryFieldsErrors } = this.state;

		if (initialCompanyInformation[e.prop] !== e.value) {
			dirtyfields[e.prop] = true;
		}
		else {
			dirtyfields[e.prop] = false;
		}

		mandatoryFieldsErrors[e.prop] = validator.validateMandatoryField(e.prop, e.value, this.state.legalEntityId,
			true, this.props.initialData.validations);


		companyInformation[e.prop] = validator.validate(e.prop, e.value);
		errors[e.prop] = [];

		this.setState({ companyInformation, dirtyfields, errors, mandatoryFieldsErrors });
	};

	hasErrors = () => {
		const { errors } = this.state;

		for (var error in errors) {
			if (errors[error] && errors[error].length) {
				return true;
			}
		}

		return false;
	};

	async IsPhoneUnique() {
		if (!!this.state.dirtyfields['phone'] || !!this.state.dirtyfields['phonePrefixId']) {
			const { errors } = this.state;
			let errorText = '';
			let result = true;

			const phoneUnique = await EmailAndPhoneValidator.IsPhoneUnique(this.state.clientData.phone, this.state.clientData.phonePrefix,
				'BUSINESS', this.state.clientData.clientId);

			if (!phoneUnique.data.success) {
				errorText = phoneUnique.errorStandardOrRateLimit;
				result = false;
			}
			else if (!phoneUnique.data.resultObject.unque) {
				errorText = 'b2b_profile_information_page_duplicate_phone_error';
				result = false;
			}

			errors["phone"] = errorText;
			this.setState({ errors });
			return result;

		}
		return true;
	}

	async IsEmailUnique() {
		if (!!this.state.dirtyfields['email']) {

			const { errors } = this.state;
			let errorText = '';
			let result = true;

			const validateEmail = await EmailAndPhoneValidator.IsEmailValid(this.state.clientData.email);
			if (!validateEmail.successCall) {
				errorText = validateEmail.errorStandardOrRateLimit;
				result = false;
			}
			else {
				const emailUnique = await EmailAndPhoneValidator.IsEmailUnique(this.state.clientData.email);
				if (!emailUnique.data.success) {
					errorText = emailUnique.errorStandardOrRateLimit;
					result = false;
				}
				else if (!emailUnique.data.resultObject.unque) {
					errorText = 'create_b2b_account_page_existing_email_field_error';
					result = false;
				}
			}
			errors["email"] = errorText;
			this.setState({ errors });
			return result;
		}
		return true;
	}

	processErrorResponse(errorCode) {
		this.setState({ errorText: errorCode });
	}

	save = async e => {
		this.notificationPopupRef.current.closeClicked();
		const { clientData, companyInformation, errors, dirtyfields, maxPhoneLength } = this.state;
		e.preventDefault();
		let error = false;

		if (!!clientData.phone) {
			if (this.state.initialClientData.phonePrefix + this.state.initialClientData.phone === clientData.phonePrefix + clientData.phone) {
				dirtyfields['phonePrefixId'] = false;
				dirtyfields['phone'] = false;
			}
			else {
				let phoneError = validator.validatePhonePrefixes(this.props.initialData.phonePrefixes, clientData.phonePrefix, clientData.phone);
				if (phoneError !== "") {
					errors["phone"] = phoneError;
					error = true;
				}
				else if (!(await this.IsPhoneUnique())) {
					error = true;
				}
			}
		}

		if (!(await this.IsEmailUnique())) {
			error = true;
		}

		if (this.hasErrorsOnSave()) {
			error = true;
		}

		if (!error) {
			if (Object.values(dirtyfields).every(v => v === false)) {
				console.log('no changes detected, skipping update.')
				return;
			}

			let proceedToSaveProfile = false;
			let emailSentSuccessfully = false;


			if (clientData.email !== this.state.initialClientData.email) {
				const resultSetEmail = (await fetcherApi.put(urlTools.constructRoute("api/EmailVerification/setEmailForB2b/" + clientData.email))).response;
				if (!resultSetEmail.data.success) {
					if (resultSetEmail.data.error === "verification_resend_limit_reached") {
						errors["email"] = "rate_limit_reached";
						this.setState({ errors });
					} else {
						errors["email"] = resultSetEmail.data.error;
						this.setState({ errors });
					}
				}
				else {
					proceedToSaveProfile = true
					emailSentSuccessfully = true;
				}
			}
			else {
				proceedToSaveProfile = true;
			}

			if (proceedToSaveProfile) {
				clientData.email = this.state.initialClientData.email;

				const data = {
					clientData,
					companyInformation
				};

				const result = (await fetcherApi.put(urlTools.constructRoute("api/B2bProfile/profileInformation"), JSON.stringify(data))).response;

				if (!result.data.success) {
					this.processErrorResponse(result.data.error);
				}
				else {
					let newProfile = result.data.resultObject;
					this.setState({
						clientData: JSON.parse(JSON.stringify(newProfile.clientData)),
						initialClientData: JSON.parse(JSON.stringify(newProfile.clientData)),
						initialCompanyInformation: JSON.parse(JSON.stringify(newProfile.companyInformation)),
						dirtyfields: {},
						errors: {},
						maxPhoneLength
					});

					const popupText = emailSentSuccessfully
						? constants.VERIFICATION_EMAIL_SENT
						: constants.DATA_SAVED;

					this.notificationPopupRef.current.showPopup(popupText);
					this.updateGreetingText(clientData.firstName + " " + clientData.lastName);

				}
			}
		}
		else {
			this.setState({ errors, maxPhoneLength });
		}
	};

	updateGreetingText(greetingText) {
		this.props.updateGreetingText(greetingText);
	}

	handleClick = event => {
		this.props.hideNavBar(true);
	}

	onDropDownChange = e => {
		const { clientData, errors, initialClientData, dirtyfields } = this.state;

		if (initialClientData['phonePrefixId'] !== e.value) {
			dirtyfields['phonePrefixId'] = true;
		}
		else {
			dirtyfields['phonePrefixId'] = false;
		}

		clientData.phonePrefixId = e.target.value;
		let index = e.nativeEvent.target.selectedIndex;
		let prefix = e.nativeEvent.target[index].text;
		clientData.phonePrefix = prefix;
		errors['phone'] = [];
		this.setState({ clientData, errors, maxPhoneLength: validator.getPhoneMinLength(this.props.initialData.phonePrefixes, prefix) });
	};

	hasErrorsOnSave() {
		let foundErrors = false;
		const { errors, mandatoryFieldsErrors } = this.state;

		for (var fieldError in mandatoryFieldsErrors) {
			if (mandatoryFieldsErrors[fieldError] !== undefined && mandatoryFieldsErrors[fieldError].length > 0) {
				errors[fieldError] = mandatoryFieldsErrors[fieldError]
				foundErrors = true;
			}
		}

		if (!foundErrors) {
			this.setState({ mandatoryFieldsErrors: {} });
		}

		this.setState({ errors });
		return foundErrors;
	};

	onDropDownCountryChange = e => {
		const { companyInformation, initialCompanyInformation, dirtyfields } = this.state;

		if (initialCompanyInformation['countryId'] !== e.value) {
			dirtyfields['countryId'] = true;
		}
		else {
			dirtyfields['countryId'] = false;
		}

		companyInformation.countryId = parseInt(e.target.value);

		this.setState({ companyInformation });
	};

	render() {
		const { clientData, legalEntityId, companyInformation, maxPhoneLength, errors, initialClientData } = this.state;

		return (clientData !== null && companyInformation != null) ? (
			<React.Fragment>
				<div className="container page-container profile-page">
					<AutoHideableTitle text={'b2b_profile_information_page_title'} />
					<div className="card card-full-width">
						<div className="card-content">
							<div className="row group-row loyalty-card">
								<div className="column-medium-12 column-desktop-12 column-tablet-12 card-account-info">
									<div className="loyalty-card-image">
										<img src={require("assets/img/rimi-card-front-" + LegalEntityFunctions.getCountryName(legalEntityId) + ".png")} alt="Rimi Card" />
									</div>
									<div className="counter card-number">
										<h3>
											<TranslationText text='b2b_profile_information_page_card_number_title' />
										</h3>
										<div className="card-digits">
											<div className="value">{clientData.card.cardPrefix}</div>
											<div className="value">{clientData.card.cardNumber}</div>
										</div>
									</div>
								</div>
								<div className="column-medium-3 column-desktop-4 column-tablet-12 profile-button-column">
									<Link className="button -secondary full-width" to="/PasswordChange">
										<TranslationText text='password_change_page_link_button' />
									</Link>
								</div>
							</div>
							<hr />
							<h2>
								<TranslationText text='b2b_profile_information_page_account_info_title' />
							</h2>
							<div className="row">

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={(!!errors['firstName'] && errors['firstName'].length > 0 ? "form-field -has-error" : "form-field") + " -required"}>
												<label htmlFor="first_name" className="form-field__label">
													<TranslationText text='b2b_profile_information_page_fname_field_title' />
												</label>
												{
													initialClientData.firstName
														? <input style={{ color: '#878380', cursor: 'default' }} className="form-field__input" type="text" name="first_name" id="first_name" value={clientData.firstName} readOnly />
														: <InputText
															onChange={this.onChange}
															prop="firstName"
															value={clientData.firstName || ""}
															maxLength="50"
															className="form-field__input"
														/>
												}
											</div>
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={errors.firstName} />
												</span>
											</div>
										</div>
									</div>
								</div>

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={(!!errors['lastName'] && errors['lastName'].length > 0 ? "form-field -has-error" : "form-field") + " -required"}>
												<label htmlFor="last_name" className="form-field__label">
													<TranslationText text='b2b_profile_information_page_lname_field_title' />
												</label>
												<InputText
													onChange={this.onChange}
													prop="lastName"
													value={clientData.lastName || ""}
													maxLength="50"
													className="form-field__input"
													type="text"
												/>
											</div>
											<div className="sublabel error-msg show"><span>
												<TranslationText text={errors.lastName} />
											</span></div>
										</div>
									</div>
								</div>

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={(!!errors['email'] && errors['email'].length > 0 ? "form-field  -has-error" : "form-field") + " -required"}>
												<label htmlFor="email" className="form-field__label">
													<TranslationText text='email_field_label' />
												</label>
												<InputText
													onChange={this.onChange}
													prop="email"
													value={clientData.email}
													maxLength="50"
													className="form-field__input"
												/>
											</div>
											<div className="sublabel error-msg show"><span>
												<TranslationText text={errors.email} />
											</span></div>
										</div>
									</div>
								</div>

							</div>

							<div className="row">

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className="form-field -required">
												<label htmlFor="country_code" className="form-field__label">
													<TranslationText text='b2b_profile_information_page_phone_field_title' />
												</label>
											</div>
										</div>
										<div className={!!errors['phone'] && errors['phone'].length > 0 ? "form-row -phone-number  -has-error" : "form-row -phone-number"}>
											<div className="form-field form-field__select">
												<select value={clientData.phonePrefixId} onChange={this.onDropDownChange}>
													{this.createSelectItems()}
												</select>
												<svg enableBackground="new 0 0 32 32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="m22.7 12.7-6.7 6.6-6.7-6.6" fill="none" stroke="#a12971" strokeMiterlimit="10" strokeWidth="2" /></svg>                            </div>
											<div className="form-field">
												<InputText
													onChange={this.onChange}
													prop="phone"
													value={clientData.phone}
													className="form-field__input -tel"
													type="tel"
													maxLength={maxPhoneLength}
												/>
											</div>
										</div>
										<div className="sublabel error-msg show"><span>
											<TranslationText text={errors.phone} />
										</span></div>
									</div>
								</div>
							</div>

							<hr />

							<h2>
								<TranslationText text='b2b_profile_information_page_comp_info_field_title' />
							</h2>

							<div className="row start">

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={(!!errors['companyName'] && errors['companyName'].length > 0 ? "form-field  -has-error" : "form-field") + " -required"}>
												<label htmlFor="company-name" className="form-field__label">
													<TranslationText text='b2b_profile_information_page_comp_name_field_title' />
												</label>

												<InputText
													onChange={this.onChangeCompany}
													prop="companyName"
													value={companyInformation.companyName}
													className="form-field__input"
													type="text"
													maxLength="140"
												/>

											</div>
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={errors.companyName} />
												</span>
											</div>
										</div>
									</div>
								</div>

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className="form-field">
												<label htmlFor="company_registration_number" className="form-field__label">
													<TranslationText text='b2b_profile_information_page_reg_number_field_title' />
												</label>
												<InputText
													value={companyInformation.registrationNumber}
													className="form-field__input"
													disabled={true}
												/>
											</div>
										</div>
									</div>
								</div>

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={(!!errors['vatRegistrationNumber'] && errors['vatRegistrationNumber'].length > 0 ? "form-field -has-error" : "form-field")}>
												<label htmlFor="company_vat_number" className="form-field__label">
													<TranslationText text='b2b_profile_information_page_vat_number_field_title' />
												</label>

												<InputText
													onChange={this.onChangeCompany}
													prop="vatRegistrationNumber"
													value={companyInformation.vatRegistrationNumber}
													className="form-field__input"
													type="tel"
													maxLength="14"
												/>
											</div>
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={errors.vatRegistrationNumber} />
												</span>
											</div>
										</div>
									</div>
								</div>

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className="form-field -required">
												<label htmlFor="country" className="form-field__label">
													<TranslationText text='b2b_profile_information_page_country_field_title' />
												</label>
											</div>
											<div className="form-field form-field__select">
												<select value={companyInformation.countryId} onChange={this.onDropDownCountryChange}>
													{this.createSelectCountryItems()}
												</select>
												<svg enableBackground="new 0 0 32 32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="m22.7 12.7-6.7 6.6-6.7-6.6" fill="none" stroke="#a12971" strokeMiterlimit="10" strokeWidth="2" /></svg>                            </div>
										</div>
									</div>
								</div>

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={(!!errors['legalAddress'] && errors['legalAddress'].length > 0 ? "form-field -has-error" : "form-field") + " -required"}>
												<label htmlFor="company_legal_address" className="form-field__label">
													<TranslationText text='b2b_profile_information_page_address_field_title' />
												</label>
												<InputText
													onChange={this.onChangeCompany}
													prop="legalAddress"
													value={companyInformation.legalAddress}
													className="form-field__input"
													type="text"
													maxLength="60"
												/>
											</div>
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={errors.legalAddress} />
												</span>
											</div>
										</div>
									</div>
								</div>

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={(!!errors['city'] && errors['city'].length > 0 ? "form-field -has-error" : "form-field") + " -required"}>
												<label htmlFor="company_city_region" className="form-field__label">
													<TranslationText text='b2b_profile_information_page_city_field_title' />
												</label>

												<InputText
													onChange={this.onChangeCompany}
													prop="city"
													value={companyInformation.city}
													className="form-field__input"
													type="text"
													maxLength="40"
												/>
											</div>
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={errors.city} />
												</span>
											</div>
										</div>
									</div>
								</div>

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={!!errors['subTown'] && errors['subTown'].length > 0 ? "form-field  -has-error" : "form-field"}>
												<label htmlFor="company_city_parish" className="form-field__label">
													<TranslationText text='b2b_profile_information_page_subtown_field_title' />
												</label>


												<InputText
													onChange={this.onChangeCompany}
													prop="subTown"
													value={companyInformation.subTown}
													className="form-field__input"
													type="text"
													maxLength="50"
												/>
											</div>
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={errors.subTown} />
												</span>
											</div>
										</div>
									</div>
								</div>

								<div className="column-medium-4 column-desktop-6 column-tablet-12">
									<div className="form-group">
										<div className="form-row">
											<div className={(!!errors['zipCode'] && errors['zipCode'].length > 0 ? "form-field -has-error" : "form-field") + " -required"}>
												<label htmlFor="company_city_postcode" className="form-field__label">
													<TranslationText text='b2b_profile_information_page_zip_field_title' />
												</label>

												<InputText
													onChange={this.onChangeCompany}
													prop="zipCode"
													value={companyInformation.zipCode}

													className="form-field__input"
													type="text"
													maxLength={legalEntityId === "2" ? "4" : "5"}

												/>


											</div>
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={errors.zipCode} />
												</span>
											</div>

										</div>
									</div>
								</div>

							</div>

							<hr />

							<div className="row profile-footer">
								<div className="column-medium-7 column-desktop-6 column-tablet-12">
									<hr className="spacer" />
									<ConsentLinks preselectedLegalEntityId={this.props.initialData.legalEntityId} business={true} />
								</div>
								<div className="column-medium-3 column-desktop-4 column-tablet-12">
									<button
										type="submit"
										onClick={this.save}
										className="button full-width"
									>
										<TranslationText text='b2b_profile_information_page_save_btn' />
									</button>
									<NotificationSuccess ref={this.notificationPopupRef}></NotificationSuccess>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer countrySelectionEnabled={false} legalEntity={this.state.legalEntityId} />
			</React.Fragment>
		) : <Spinner />
	}
}


export default withTranslation()(BusinessProfileInformation);
