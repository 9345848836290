import React, { Component, createRef } from "react";
import InputText from "../components/InputText.jsx";
import validator from "../components/FieldValidator.jsx";
import "regenerator-runtime/runtime";
import fetcherApi from '../components/FetcherApi.jsx';
import Spinner from '../profile/Information/Spinner.jsx';
import { Link, withRouter } from "react-router-dom";
import LegalEntityFunctions from "../../components/components/LegalEntityFunctions.jsx";
import Footer from "../../components/components/Footer.jsx";
import NotificationSuccess from "../components/Notifications/NotificationSuccess.jsx";
import ConsentLinks from "../components/Consents/ConsentLinks.jsx";
import * as constants from "../constants/notificationTypes.jsx";
import TranslationText from "../components/Translation/TranslationText.jsx";
import EmailAndPhoneValidator from '../components/EmailAndPhoneValidator.jsx';
import { withTranslation } from 'react-i18next';
import AutoHideableTitle from "../components/HeaderComponents/AutoHideableTitle.jsx";
import { CardTypeCodes } from "./CardTypeCodes.jsx";
import { DateInput } from '../components/DateInput.jsx';
import urlTools from "../tools/urlTools.jsx";
import { TemporarySuccessDisplay } from "../components/SuccessDisplay/TemporarySuccessDisplay.jsx";

class ProfileInformation extends Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.notificationPopupRef = createRef();
	}
	state = {
		phonePrefixes: this.props.initialData.phonePrefixes.map(data => ({ label: data.value, value: data.key })),
		errors: {},
		client: null,
		submiterror: "",
		initialcustomer: {},
		dirtyfields: {},
		mandatoryFieldsErrors: {},
		legalEntityId: this.props.initialData.legalEntityId,
		maxPhoneLength: "",
		validations: {},
		errorText: "",
		cardReplacementPathName: "",
		isCopySuccessDisplayed: false
	};

	createSelectItems() {
		const { phonePrefixes } = this.state;
		let items = [];
		for (let i = 0; i < phonePrefixes.length; i++) {
			let label = phonePrefixes[i].label;
			let key = phonePrefixes[i].value;
			items.push(<option key={label} value={key}>{label}</option>);
		}
		return items;
	}

	async componentDidMount() {
		this._isMounted = true;
		const client = (await fetcherApi.get(urlTools.constructRoute("api/profile/profileInformation/" + this.state.legalEntityId))).response.data.resultObject;

		if (this._isMounted) {
			this.setState({
				maxPhoneLength: validator.getPhoneMinLength(this.props.initialData.phonePrefixes, client.phonePrefix),
				client,
				initialcustomer: JSON.parse(JSON.stringify(client)),
				dirtyfields: {}, errors: {},
				validations: this.props.initialData.validations,
				cardReplacementPathName: client.card.cardIsVirtual ? '/cardReplacement' : '/cardTypeSelection/'
			});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.setState = (state, callback) => {
			return;
		}
	}

	onCopy = (client) => {
		this.writeToClipboard(client.card.fullCardNumber);
		this.setState({ isCopySuccessDisplayed: true });
	}

	onCopySuccessDisplayExpiration = () => {
		this.setState({ isCopySuccessDisplayed: false });
	}

	onBirthDateChange = value => {
		const { client, errors, dirtyfields, initialcustomer } = this.state;
		errors['birthDate'] = [];
		client['birthDate'] = value;
		dirtyfields['birthDate'] = initialcustomer.birthDate !== value;

		this.setState({ client, errors, dirtyfields });
	}

	onChange = e => {
		const { client, initialcustomer, mandatoryFieldsErrors, dirtyfields, errors } = this.state;

		if (e.prop === 'email') {
			e.value = validator.blockSpaceSymbol(e.value);
		}

		if (initialcustomer[e.prop] !== e.value) {
			dirtyfields[e.prop] = true;
		}
		else {
			dirtyfields[e.prop] = false;
		}

		if (e.prop === 'lastName' || e.prop === 'name') {
			client[e.prop] = validator.blockUnwantedSymbols(e.value);
		}
		else if (e.prop === 'email') {

			if (!EmailAndPhoneValidator.IsEmailStructureValid(e.value)) {
				mandatoryFieldsErrors[e.prop] = 'email_hint_page_not_valid_email_error';
			}
			else {
				mandatoryFieldsErrors[e.prop] = "";
			}
			client['email'] = validator.blockSpaceSymbol(e.value);
		}
		else {
			client[e.prop] = validator.validate(e.prop, e.value);
		}
		errors[e.prop] = [];

		this.setState({ client, dirtyfields, errors, mandatoryFieldsErrors });
	};

	hasDirtyFields = () => {
		const { dirtyfields } = this.state;
		for (var dirtyField in dirtyfields) {

			if (dirtyfields[dirtyField]) {
				return true;
			}
		}
		return false;
	}

	hasErrorsOnSave() {
		let foundErrors = false;
		const { errors, mandatoryFieldsErrors } = this.state;

		for (var fieldError in mandatoryFieldsErrors) {
			if (mandatoryFieldsErrors[fieldError] !== undefined && mandatoryFieldsErrors[fieldError].length > 0) {
				errors[fieldError] = mandatoryFieldsErrors[fieldError]
				foundErrors = true;
			}
		}

		if (!foundErrors) {
			this.setState({ mandatoryFieldsErrors: {} });
		}

		this.setState({ errors });

		if (!foundErrors)
			return this.hasErrors();

		return foundErrors;
	};


	hasErrors = () => {
		const { errors } = this.state;
		for (var error in errors) {
			if (errors[error] && errors[error].length) {
				return true;
			}
		}

		return false;
	};

	validateMandatoryFields() {
		const { mandatoryFieldsErrors, client } = this.state;
		Object.keys(this.state.client).map(function (keyName, keyIndex) {
			mandatoryFieldsErrors[keyName] = validator.validateMandatoryField(keyName, client[keyName]);
		})
		this.setState({ mandatoryFieldsErrors });
	}

	async IsPhoneUnique() {
		if (!!this.state.dirtyfields['phone'] || !!this.state.dirtyfields['phonePrefixId']) {
			const { errors } = this.state;
			let errorText = '';
			let result = true;

			const phoneUnique = await EmailAndPhoneValidator.IsPhoneUnique(this.state.client.phone,
				this.state.client.phonePrefix, 'RETAIL', this.state.client.clientsId);

			if (!phoneUnique.data.success) {
				errorText = phoneUnique.errorStandardOrRateLimit;
				result = false;
			}
			else if (!phoneUnique.data.resultObject.unque) {
				errorText = 'profile_information_page_duplicate_phone_error';
				result = false;
			}

			errors["phone"] = errorText;
			this.setState({ errors });
			return result;
		}
		return true;
	}

	IsEmailValid() {
		if (!this.state.dirtyfields.email) {
			return true;
		}

		const isStructureValid = EmailAndPhoneValidator.IsEmailStructureValid(this.state.client.email);
		if (!isStructureValid) {
			const { errors } = this.state;
			errors.email = "account_found_but_no_email_page_in_login_empty_email_field_error";
			this.setState({ errors });
		}

		return isStructureValid;
	}

	processErrorResponse(errorCode) {
		const { errors } = this.state;
		if (errorCode === "verification_resend_limit_reached" || errorCode === "email_validation_limit_reached") {
			errors.email = "rate_limit_reached";
			this.setState({ errors });
		} else if (errorCode === "email_placeholer") {
			errors.email = "account_found_but_no_email_page_incorrect_email_error";
			this.setState({ errors });
		} else if (errorCode === "duplicate_email") {
			errors.email = "account_found_but_no_email_page_in_login_duplicate_email_error";
			this.setState({ errors });
		} else {
			this.setState({ errorText: errorCode });
		}
	}

	save = async e => {
		this.notificationPopupRef.current.closeClicked();
		const { client, initialcustomer, errors, validations, dirtyfields, maxPhoneLength } = this.state;
		e.preventDefault();

		this.validateMandatoryFields();

		let error = false;

		if (!initialcustomer.birthDate) {
			errors['birthDate'] = validator.validateBirthDate(
				client.birthDate,
				validations,
				undefined,
				"add_your_data_page_birthdate_error_min_age"
			);
		}

		if (!!client.phone) {
			if (initialcustomer.phonePrefix + initialcustomer.phone === client.phonePrefix + client.phone) {
				dirtyfields['phonePrefixId'] = false;
				dirtyfields['phone'] = false;
			}
			else {
				let phoneError = validator.validatePhonePrefixes(this.props.initialData.phonePrefixes, client.phonePrefix, client.phone);
				if (phoneError !== "") {
					errors["phone"] = phoneError;
					error = true;
				} else if (!(await this.IsPhoneUnique())) {
					error = true;
				}
			}
		}

		if (!this.IsEmailValid()) {
			error = true;
		}

		if (this.hasErrorsOnSave()) {
			error = true;
		}

		if (!error) {
			if (Object.values(dirtyfields).every(v => v === false)) {
				console.log('no changes detected, skipping update.')
				return;
			}

			const data = { client };

			if (!initialcustomer.birthDate) {
				data.client.birthDate = client.birthDate;
			}
			const result = (await fetcherApi.put(urlTools.constructRoute("api/profile/profileInformation"), JSON.stringify(data))).response;
			if (!result.data.success) {
				this.processErrorResponse(result.data.error);
			}
			else {
				const newCustomer = result.data.resultObject;
				const emailSentSuccessfully = client.email !== initialcustomer.email;

				this.setState({
					client: newCustomer,
					initialcustomer: JSON.parse(JSON.stringify(newCustomer)),
					dirtyfields: {},
					errors: {},
					mandatoryFieldsErrors: {},
					maxPhoneLength,
					errorText: ""
				});

				this.updateGreetingText(client.name + " " + client.lastName);
				const popupText = emailSentSuccessfully
					? constants.VERIFICATION_EMAIL_SENT
					: constants.DATA_SAVED;

				this.notificationPopupRef.current.showPopup(popupText);
			}
		}
		else {
			this.setState({ errors, maxPhoneLength });
		}

	};

	updateGreetingText(greetingText) {
		this.props.updateGreetingText(greetingText);
	}

	handleClick = event => {
		this.props.hideNavBar(true);
	}

	onDropDownChange = e => {
		const { client, errors, dirtyfields, initialcustomer } = this.state;
		let index = e.nativeEvent.target.selectedIndex;
		let prefix = e.nativeEvent.target[index].text;
		const selectedPhonePrefixId = e.target.value;

		if (initialcustomer['phonePrefixId'] !== selectedPhonePrefixId) {
			dirtyfields['phonePrefixId'] = true;
		}
		else {
			dirtyfields['phonePrefixId'] = false;
		}

		client.phonePrefixId = selectedPhonePrefixId;
		client.phonePrefix = prefix;
		errors.phone = [];

		this.setState({
			client,
			errors,
			maxPhoneLength: validator.getPhoneMinLength(this.props.initialData.phonePrefixes, prefix)
		});
	};

	handleGenderChange = e => {
		const { name, value } = e.target;
		const { client, initialcustomer, dirtyfields, errors } = this.state;

		var genderCode = value == 'male' ? "1" : "2";

		if (initialcustomer[name] !== genderCode) {
			dirtyfields[name] = true;
		}
		else {
			dirtyfields[name] = false;
		}

		errors['gender'] = [];

		client[name] = genderCode;
		this.setState({ client, errors });
	};

	writeToClipboard = text => {
		navigator.clipboard.writeText(text);
	}

	render() {
		const { client, maxPhoneLength, submiterror, errors, legalEntityId, initialcustomer } = this.state;

		return client !== null ? (
			<React.Fragment>
				<div className="container page-container profile-page">
					<AutoHideableTitle text={'profile_information_page_menu_bar_profile_info'} />
					{
						submiterror !== "" ? (<p>submiterror</p>) : <span></span>
					}
					<div className="card card-full-width">
						<div className="card-content">
							<div className="loyalty-card group-row">
								<div className="card-account-info row">
									<div className="loyalty-card-image">
										<img
											src={require("assets/img/rimi-card-front-" + LegalEntityFunctions.getCountryName(legalEntityId) + '.png')}
											alt="Rimi Card" />
									</div>
									<div className="counter collected-money">
										<h3>
											<TranslationText text='profile_information_page_my_rimi_money' />
										</h3>
										<div className="value">{client.myRimiMoney}</div>
									</div>
									<div className="counter collected-stickers">
										<h3>
											<TranslationText text='profile_information_page_dgtl_stickers' />
										</h3>
										<div className="value">{client.stickers}</div>
									</div>
									{
										initialcustomer.cardtypeId === CardTypeCodes.EMPLOYEE ?
											<div className="counter collected-stickers">
												<h3>
													<TranslationText text='profile_information_page_empl_discount_amount' />
												</h3>
												<div className="value">{client.employeeDiscountAmount}</div>
											</div> : <div></div>
									}
								</div>
								<div className="card-row row">
									<div className="counter card-number">
										<h3>
											<TranslationText text='profile_information_page_card_number' />
										</h3>
										<div className="card-digits">
											<div className="value">{client.card.cardPrefix}</div>
											<div className="value">{client.card.cardNumber}</div>
											<span className="copy-icon-wrapper" onClick={_ => this.onCopy(client)}>
												<img src={require("assets/svg/icons/copy.svg").default} alt="copy-icon" />
											</span>
											<TemporarySuccessDisplay isDisplayed={this.state.isCopySuccessDisplayed} onExpiration={_ => this.onCopySuccessDisplayExpiration()} />
										</div>
									</div>
									<div className="column-medium-3 column-desktop-4 column-tablet-12 profile-button-column">
										<Link className="button -secondary full-width" to="/PasswordChange">
											<TranslationText text='password_change_page_link_button' />
										</Link>
										<Link className="button -secondary full-width" onClick={this.handleClick.bind(this)} to={{ pathname: this.state.cardReplacementPathName }}>
											<TranslationText text='profile_information_page_replace_card_btn' />
										</Link>
									</div>
								</div>
							</div>
							<hr />

							<div className="row group-row">

								<div className="column-desktop-12">
									<h2 style={{ marginBottom: '0px' }}>
										<TranslationText text='profile_information_page_personal_data' />
									</h2>
									<div className="row padded-row">
										<div className="column-desktop-4 column-tablet-6 column-mobile-12">
											<div className={errors['name'] !== undefined && errors['name'].length > 0 ? "form-field  -has-error" : "form-field"}>
												<label htmlFor="first_name" className="form-field__label">
													<TranslationText text='profile_information_page_fname_field_title' />
												</label>
												{
													initialcustomer.name
														? <input style={{ color: '#878380', cursor: 'default' }} className="form-field__input" type="text" value={client.name || ""} disabled readOnly />
														: <InputText
															onChange={this.onChange}
															prop="name"
															value={client.name || ""}
															maxLength="50"
														/>
												}
											</div>
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={errors.name} />
												</span>
											</div>
										</div>
										<div className="column-desktop-4 column-tablet-6 column-mobile-12">
											<div className={errors['lastName'] !== undefined && errors['lastName'].length > 0 ? "form-field  -has-error" : "form-field"}>
												<label htmlFor="last_name" className="form-field__label">
													<TranslationText text='profile_information_page_lname_field_title' />
												</label>
												<InputText
													onChange={this.onChange}
													prop="lastName"
													value={client.lastName || ""}
													maxLength="50"
												/>

											</div>
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={errors.lastName} />
												</span>
											</div>
										</div>
										<div className="column-desktop-4 column-tablet-6 column-mobile-12">
											<div className={errors['birthDate'] !== undefined && errors['birthDate'].length > 0 ? "form-field -has-error" : "form-field"}>
												<label htmlFor="birth_date" className="form-field__label">
													<TranslationText text='profile_information_page_birthdate_field_title' />
												</label>
												<DateInput
													initialDateString={client.birthDate}
													readOnly={!!initialcustomer.birthDate}
													errorMessage={errors.birthDate}
													onChange={this.onBirthDateChange}
												/>

											</div>
										</div>
									</div>
									<div className="row padded-row">
										<div className="column-desktop-4 column-tablet-6 column-mobile-12">
											<div className={errors['email'] !== undefined && errors['email'].length > 0 ? "form-field  -has-error" : "form-field"}>
												<label htmlFor="first_name" className="form-field__label">
													<TranslationText text='profile_information_page_email_field_title' />
												</label>
												<InputText
													onChange={this.onChange}
													prop="email"
													value={client.email || ""}
													maxLength="50"
												/>
											</div>
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={errors.email} />
												</span>
											</div>
										</div>
										<div className="column-desktop-4 column-tablet-6 column-mobile-12">
											<div className="form-group no-margin-bottom">
												<div className="form-row">
													<div className="form-field">
														<label htmlFor="email" className="form-field__label">
															<TranslationText text='profile_information_page_phone_field_title' />
														</label>
													</div>
												</div>
												<div className={errors['phone'] !== undefined && errors['phone'].length > 0 ? "form-row -phone-number  -has-error" : "form-row -phone-number"}>
													<div className="form-field form-field__select">
														<select value={client.phonePrefixId} onChange={this.onDropDownChange}>
															{this.createSelectItems()}
														</select>
														<svg enableBackground="new 0 0 32 32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
															<path d="m22.7 12.7-6.7 6.6-6.7-6.6" fill="none" stroke="#a12971" strokeMiterlimit="10" strokeWidth="2"></path>
														</svg>
													</div>
													<div className="form-field">
														<InputText
															onChange={this.onChange}
															prop="phone"
															value={client.phone || ""}
															className="form-field__input -tel"
															type="tel"
															maxLength={maxPhoneLength}
														/>
													</div>
												</div>
												<div className="sublabel error-msg show">
													<span>
														<TranslationText text={errors.phone} />
													</span>
												</div>
											</div>
										</div>
										<div className="column-desktop-4 column-tablet-6 column-mobile-12">
											<div className={errors['gender'] !== undefined && errors['gender'].length > 0 ? "form-field -radio gender -has-error" : "form-field -radio gender"}>
												<label className="form-field__label">
													<TranslationText text='profile_information_page_gender_title' />
												</label>
												<div className="form-check inline">
													<input onChange={this.handleGenderChange} className="form-check-input" name="gender" type="radio" value="male" id="male"
														checked={client.gender === "1"} disabled={initialcustomer.gender}
													/>
													<label className="form-check-label" htmlFor="male">
														<TranslationText text='profile_information_page_gender_male' />
														<span></span>
													</label>
												</div>
												<div className="form-check inline">
													<input onChange={this.handleGenderChange} className="form-check-input" name="gender" type="radio" value="female" id="female"
														checked={client.gender === "2"} disabled={initialcustomer.gender}
													/>
													<label className="form-check-label" htmlFor="female">
														<TranslationText text='profile_information_page_gender_female' />
														<span></span>
													</label>
												</div>
											</div>
											<div className="sublabel error-msg show">
												<span>
													<TranslationText text={errors.gender} />
												</span>
											</div>
										</div>
									</div>
								</div>
								<div className="btn-group">
								</div>
							</div>
							<hr />
							<div className="row profile-footer">
								<div className="column-medium-9 column-desktop-8 column-tablet-12">
									<ConsentLinks preselectedLegalEntityId={this.props.initialData.legalEntityId} />
								</div>
								<div className="column-medium-3 column-desktop-4 column-tablet-12">
									<div className="form-row">
										<div className="sublabel error-msg show">
											<span>{<TranslationText text={this.state.errorText} />}</span>
										</div>
									</div>
									<button
										type="submit"
										onClick={this.save}
										className="button full-width"
									>
										<TranslationText text='profile_information_page_save_btn' />
									</button>
									<NotificationSuccess ref={this.notificationPopupRef}></NotificationSuccess>
								</div>
							</div>
						</div>
					</div>
					{/* LS-2295 created to fix this */}
					{/* <Prompt
                        when={this.hasErrors()}
                        message={i18n.t('profile_view_errored_fields_prompt_message')}
                    />
                    <Prompt
                        when={this.hasDirtyFields()}
                        message={i18n.t('profile_view_dirty_fields_prompt_message')}
                    /> */}
				</div>
				<Footer countrySelectionEnabled={false} legalEntity={this.state.legalEntityId} />
			</React.Fragment>
		) : <Spinner />
	}
}


export default withRouter(withTranslation()(ProfileInformation));
