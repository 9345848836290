import React, { PureComponent } from "react";
import * as constants from "../../constants/constants.jsx";
import * as languageCodes from "../../constants/languageCodes.jsx";
import CookieConsentsBot from "react-cookiebot";

class CookieBotInstance extends PureComponent {

  state = {
    selectedLanguage: ""
  }

  getCookie(name) {
    const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
  };

  componentDidMount() {
    this.setState({ selectedLanguage: localStorage.getItem(constants.SELECTED_LANGUAGE) })
  }

  getLanguageForCookieBot() {
    let language = this.state.selectedLanguage;

    if (!(!!language)) {
      return languageCodes.ENGLISH;
    }

    if (language === languageCodes.ENGLISH_US) {
      language = languageCodes.ENGLISH;
    }

    return language;
  }

  render() {
    return (
      <CookieConsentsBot language={this.getLanguageForCookieBot()} domainGroupId={this.props.cookieBotId} />
    );
  }
}

export default CookieBotInstance;