import fetcherApi from '../components/FetcherApi.jsx';
import urlTools from "../tools/urlTools.jsx";

export default {
  async IsEmailUnique(email) {
    return fetch('IsEmailDuplicated/', '/api/Duplication/', email, '');
  },
  // if clientId is 0, it means the user does not exclude self in the uniqueness check
  async IsPhoneUnique(phone, phonePrefix, target, clientId = 0) {
    return fetch('IsPhoneDuplicated/', '/api/Duplication/', phone + '/' + phonePrefix, '/' + `${target}/${clientId}`);
  },
  async IsEmailValid(email) {
    let successful = true;
    let error = "";

    if (!this.IsEmailStructureValid(email)) {
      error = "account_found_but_no_email_page_in_login_empty_email_field_error";
      successful = false;
    }
    
    return {
      ['errorStandardOrRateLimit']: error,
      ['successCall']: successful
    };
  },
  IsEmailStructureValid: email => /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email ?? "")
};

let fetch = async function (action, apiCall, argument, target) {
  const url = urlTools.constructRoute(apiCall + action + argument + target);
  const result = (await fetcherApi.get(url)).response;
  let errorText = '';

  if (result.status !== 200) {
    errorText = result.error;
  }

  return {
    ['errorStandardOrRateLimit']: errorText,
    ['successCall']: !(!!errorText),
    ['data']: !!errorText ? "" : result.data
  };
};
