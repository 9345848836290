import { SELECTED_COUNTRY } from "components/constants/constants.jsx";
import * as languageCodes from '../constants/languageCodes.jsx';

export default {
  getPhonePrefix(selectedCountry) {
    switch (selectedCountry) {
      case "lt":
        return "370";
      case "lv":
        return "371";
      case "ee":
        return "372";
      default:
        return "371";
    }
  },
  getPhonePrefixId(selectedCountry) {
    switch (selectedCountry) {
      case "lt":
        return "52";
      case "lv":
        return "53";
      case "ee":
        return "54";
      default:
        return "53";
    }
  },
  getLegalEntityId(selectedCountry) {
    switch (selectedCountry) {
      case "lt":
        return "1";
      case "lv":
        return "2";
      case "ee":
        return "3";
      default:
        return "";
    }
  },
  getCountryName(legalEntity) {
    switch (legalEntity) {
      case "1":
        return "lt";
      case "2":
        return "lv";
      case "3":
        return "ee";
      default:
        return "";
    }
  },
  getLanguageIsoCode(code) {
    if (code === null) {
      return languageCodes.ENGLISH
    }

    switch (code.toLowerCase()) {
      case languageCodes.LITHUANIAN:
        return languageCodes.LITHUANIAN;
      case languageCodes.RUSSIAN:
        return languageCodes.RUSSIAN;
      case languageCodes.LATVIAN:
        return languageCodes.LATVIAN;
      case languageCodes.ENGLISH:
      case languageCodes.ENGLISH_US:
        return languageCodes.ENGLISH;
      case languageCodes.ESTONIAN:
      case "ee":
        return languageCodes.ESTONIAN;
      default:
        return languageCodes.ENGLISH;
    }
  },
  getRedirectToWebLink(initialData) {
    const selectedCountry = localStorage.getItem(SELECTED_COUNTRY);
    switch (selectedCountry) {
      case "lt":
        return initialData.myRimiBaseUrlLt;
      case "lv":
        return initialData.myRimiBaseUrlLv;
      case "ee":
        return initialData.myRimiBaseUrlEe;
      default:
        return "#";
    }
  },
  getRedirectToEcomLink(initialData) {
    const selectedCountry = localStorage.getItem(SELECTED_COUNTRY);
    switch (selectedCountry) {
      case "lt":
        return initialData.ecomBaseUrlLt;
      case "lv":
        return initialData.ecomBaseUrlLv;
      case "ee":
        return initialData.ecomBaseUrlEe;
      default:
        return "#";
    }
  }
};
