import React, { useContext, useEffect, useState } from "react";
import LegalEntityFunctions from "../../components/components/LegalEntityFunctions.jsx";
import {
  NavLink,
} from 'react-router-dom';
import * as constants from "../constants/constants.jsx";
import i18n from "translations/i18n.jsx";
import TranslationText from "./Translation/TranslationText.jsx";
import ViewportFunctions from "../tools/ViewportFunctions.jsx";
import FetcherApi from "./FetcherApi.jsx";
import { UserContext } from "../tools/context/UserContext.jsx";
import CountryService from "../tools/localization/CountryService.jsx";

const Footer = (props) => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [toggleShowLanguagesDropdown, setToggleShowLanguagesDropdown] = useState(false);
  const [toggleShowCountriesDropdown, setToggleShowCountriesDropdown] = useState(false);
  const [isMobileApp, setIsMobileApp] = useState("");

  const getCountryFromLegalEntity = () => {
    let legalEntity = props.legalEntity;
    if (legalEntity !== null && legalEntity !== undefined && legalEntity !== "") {
      let country = LegalEntityFunctions.getCountryName(legalEntity);
      CountryService.setCountry(country);
      if (country !== null) {
        setSelectedCountry(country);
      }
    }
    else {
      let countryLocal = CountryService.getCountry();
      if (countryLocal !== null) {
        setSelectedCountry(countryLocal);
      }
    }
  }

  useEffect(() => {
    getCountryFromLegalEntity();
    setIsMobileApp(localStorage.getItem(constants.IS_MOBILE_APP_STORAGE_KEY));
  });

  const setCountry = (e, country) => {
    e.preventDefault();
    if (props.countrySelectionEnabled) {
      setSelectedCountry(country);
      CountryService.setCountry(country);
    }

    const isMobileLayout = ViewportFunctions.isMobileLayout();
    const updatedToggleShowCountriesNavValue = isMobileLayout ? !toggleShowCountriesDropdown : false;
    setToggleShowCountriesDropdown(updatedToggleShowCountriesNavValue);
  }

  const userContext = () => useContext(UserContext);
  const isUserLoggedIn = userContext();

  const languageChanged = async (e, language) => {
    e.preventDefault();
    const updateLanguageApi = '/api/profile/updateLanguage/';

    i18n.changeLanguage(language);

    if (!!isUserLoggedIn) {
      await FetcherApi.put(updateLanguageApi + language);
    }

    const isMobileLayout = ViewportFunctions.isMobileLayout();
    const updatedToggleShowLanguagesNavValue = isMobileLayout ? !toggleShowLanguagesDropdown : false;
    setToggleShowLanguagesDropdown(updatedToggleShowLanguagesNavValue);

  }

  const getCountryNameFromCode = (code) => {
    switch (code) {
      case 'lv':
        return <TranslationText text='country_name_latvia' />;
      case 'ee':
        return <TranslationText text='country_name_estonia' />;
      case 'lt':
        return <TranslationText text='country_name_lithuania' />;
      default:
        break;
    }
  }

  const showCountrySelection = () => {
    return props.countrySelectionEnabled;
  }

  return isMobileApp === constants.TRUE ? <React.Fragment />
    :
    (<React.Fragment>
      <footer className="main-footer">
        <div className="container">
          <h2><TranslationText text="footer_language_and_country" /></h2>
          <nav className={toggleShowLanguagesDropdown ? "languages show-nav" : "languages"}>
            <span className="icon">
              <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="m0 0h24v24h-24z" /><path d="m12.87 15.07-2.54-2.51.03-.03c1.74-1.94 2.98-4.17 3.71-6.53h2.93v-2h-7v-2h-2v2h-7v1.99h11.17c-.67 1.93-1.73 3.76-3.17 5.36-.93-1.03-1.7-2.16-2.31-3.35h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02 1.42 1.42 5-5 3.11 3.11zm5.63-5.07h-2l-4.5 12h2l1.12-3h4.75l1.13 3h2zm-2.62 7 1.62-4.33 1.62 4.33z" fill="#a4afb3" /></g></svg>                    </span>
            <NavLink to='#' onClick={e => languageChanged(e, 'en')} isActive={() => i18n.language === 'en'} className="nav-link nav-item" ><TranslationText text='footer_english' /></NavLink>
            <NavLink to='#' onClick={e => languageChanged(e, 'lv')} isActive={() => i18n.language === 'lv'} className="nav-link nav-item" ><TranslationText text='footer_latvian' /></NavLink>
            <NavLink to='#' onClick={e => languageChanged(e, 'ru')} isActive={() => i18n.language === 'ru'} className="nav-link nav-item" ><TranslationText text='footer_russian' /></NavLink>
            <NavLink to='#' onClick={e => languageChanged(e, 'lt')} isActive={() => i18n.language === 'lt'} className="nav-link nav-item" ><TranslationText text='footer_lithuanian' /></NavLink>
            <NavLink to='#' onClick={e => languageChanged(e, 'et')} isActive={() => i18n.language === 'et'} className="nav-link nav-item" ><TranslationText text='footer_estonian' /></NavLink>
          </nav>
          <nav className={toggleShowCountriesDropdown ? "locations show-nav" : "locations"}>
            <span className="icon">
              <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd"><path d="m0 0h24v24h-24z" />
                  <g stroke="#a4afb3" strokeWidth="2" transform="translate(2 2)">
                    <path d="m20 10c0 5.5230435-4.4769565 10-10 10-5.52304348 0-10-4.4769565-10-10 0-5.5226087 4.47695652-10 10-10 5.5230435 0 10 4.4773913 10 10z" />
                    <path d="m0 10h20" />
                    <path d="m2.445 3.40882609c1.10391304.68 2.63086957 1.81869565 7.55478261 1.81869565 4.92434779 0 6.21999999-1.10391304 7.51826089-1.81826087" />
                    <path d="m17.6371304 16.4561304c-1.103913-.68-2.7495652-1.9108695-7.67391301-1.9108695-4.92434782 0-6.32782609 1.1656521-7.62652174 1.88" />
                    <path d="m14.318087 10c0 5.5230435-1.8939131 10-4.23 10-2.33652178 0-4.23043483-4.4769565-4.23043483-10 0-5.5226087 1.89391305-10 4.23043483-10 2.3360869 0 4.23 4.4773913 4.23 10z" /></g></g>
              </svg>
            </span>
            {!showCountrySelection() && (
              <NavLink onClick={e => setCountry(e, selectedCountry)} className="nav-link nav-item"
                style={{ fontWeight: "bold" }} isActive={() => false} to='#'>{getCountryNameFromCode(selectedCountry)}
              </NavLink>
            )}
            {showCountrySelection() && (
              <React.Fragment>
                <NavLink onClick={e => setCountry(e, 'lv')} className="nav-link nav-item" activeStyle={{
                  fontWeight: "bold",
                }} isActive={() => selectedCountry === 'lv'} to='#'>{getCountryNameFromCode('lv')}</NavLink>
                <NavLink onClick={e => setCountry(e, 'ee')} className="nav-link nav-item" activeStyle={{
                  fontWeight: "bold",
                }} isActive={() => selectedCountry === 'ee'} to='#'>{getCountryNameFromCode('ee')}</NavLink>
                <NavLink onClick={e => setCountry(e, 'lt')} className="nav-link nav-item" activeStyle={{
                  fontWeight: "bold",
                }} isActive={() => selectedCountry === 'lt'} to='#'>{getCountryNameFromCode('lt')}</NavLink>
              </React.Fragment>
            )}
          </nav>
        </div>
      </footer>
    </React.Fragment>)
};

export default Footer;