import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CookieBotInstance from "../CookieBot/CookieBotInstance.jsx";
import CookieBotService from "../../services/CookieBotService.js";

class CookieBot extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isMounted: false
    };
  }
  componentDidMount() {
    this.setState({
      isMounted: true
    });

    const queryString = this.props.location.search;
    CookieBotService.verifyUserAgentSuitability(queryString);
  }

  render() {
    return this.state.isMounted ? (
      <CookieBotInstance cookieBotId={this.props.cookieBotId} />
    ) : null;
  }
}

export default withRouter(CookieBot);