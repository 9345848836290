class cookieBotAddition {
    constructor() {
        this.detailsRow = '';
        this.detailsBody = '';
        this.acceptButtonWrapper = '';
        this.settingsButton = '';
        this.overlay = '';
        this.BUTTON_ACCEPT = 'CybotCookiebotDialogBodyButtonAccept';
        this.BUTTON_ACCEPT_ALL = 'CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll';
        this.BUTTON_DECLINE = 'CybotCookiebotDialogBodyButtonDecline';
    }

    init() {
        this.checkIfPresent();
    }

    checkIfPresent() {
        var interval = setInterval(() => {
            var dialog = document.getElementById('CybotCookiebotDialog');
            if (dialog != null) {
                clearInterval(interval);

                this.updateSettingsDialog();
                this.personalizedButtonClick();
                this.acceptAllButtonClick();
                this.declineButtonClick();
            }
        }, 100);
    }

    updateSettingsDialog() {
        // Add overlay
        this.overlay = document.createElement('div');
        this.overlay.classList.add('cookies-overlay');
        document.body.appendChild(this.overlay);

        var originalOptionButtons = document.getElementById('CybotCookiebotDialogBodyButtons');
        originalOptionButtons.style.display = 'block';

        var acceptAllButton = document.getElementById(this.BUTTON_ACCEPT_ALL);
        acceptAllButton.classList.add('button');
        var acceptButton = document.getElementById(this.BUTTON_ACCEPT);
        acceptButton.parentNode.replaceChild(acceptAllButton, acceptButton);

        var settingsButton = document.getElementById('CybotCookiebotDialogBodyButtonDetails');
        settingsButton.classList.add('button');

        var declineButton = document.getElementById(this.BUTTON_DECLINE);
        declineButton.classList.add('button');

        settingsButton.after(declineButton);

        // Hide personalized details
        this.detailsRow = document.getElementById('CybotCookiebotDialogBodyLevelButtons');
        this.detailsBody = document.getElementById('CybotCookiebotDialogDetail');
        this.acceptButtonWrapper = document.getElementById('CybotCookiebotDialogBodyLevelWrapper');
        this.settingsButton = document.getElementById('CybotCookiebotDialogBodyButtonDetails');
        this.detailsRow.classList.add('hidden');
        this.detailsBody.classList.add('hidden');
        this.acceptButtonWrapper.classList.add('hidden');
        this.settingsButton.classList.add('content-hidden');
    }

    personalizedButtonClick() {
        this.settingsButton.addEventListener('click', (e) => {
            e.preventDefault();

            if (this.settingsButton.classList.contains('content-hidden')) {
                this.detailsRow.classList.remove('hidden');
                this.detailsBody.classList.remove('hidden');
                this.acceptButtonWrapper.classList.remove('hidden');
                this.settingsButton.classList.remove('content-hidden');
            } else {
                this.detailsRow.classList.add('hidden');
                this.detailsBody.classList.add('hidden');
                this.acceptButtonWrapper.classList.add('hidden');
                this.settingsButton.classList.add('content-hidden');
            }
        });
    }

    acceptAllButtonClick() {
        var acceptButton = document.getElementById(this.BUTTON_ACCEPT_ALL);

        acceptButton.addEventListener('click', () => {
            // consent sending is handled by Cookiebot script
            this.overlay.classList.add('hidden');
        });

        var customizedAccept = document.getElementById('CybotCookiebotDialogBodyLevelButtonAccept');
        customizedAccept.addEventListener('click', () => {
            this.overlay.classList.add('hidden');
        })
    }

    declineButtonClick() {
        var declineButton = document.getElementById(this.BUTTON_DECLINE);

        declineButton.addEventListener('click', () => {
            // consent sending is handled by Cookiebot script
            this.overlay.classList.add('hidden');
        });
    }
}

var botAddition = new cookieBotAddition();
botAddition.init();